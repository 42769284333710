.reset-password-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reset-password-success h1 {
  margin-top: 0;
  font-size: 1.5em;
}

.reset-password-success p {
  font-size: 1em;
}
