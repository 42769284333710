.button-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    z-index: 1000;
  }
  
  .styled-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .styled-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .styled-button-primary {
    background-color: #3f51b5;
    color: white;
    border: none;
  }
  
  .styled-button-primary:hover {
    background-color: #303f9f;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .styled-button-secondary {
    background-color: white;
    color: #3f51b5;
    border: 2px solid #3f51b5;
  }
  
  .styled-button-secondary:hover {
    background-color: #f1f1f1;
  }
  