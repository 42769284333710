.admin-section {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.admin-section:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.admin-button {
  margin-top: 16px;
  color: white;
  transition: background-color 0.3s ease;
}

.admin-button:hover {
  background-color: #0d47a1;
}
