@keyframes bounceInLetters {
  0% {
    opacity: 0;
    transform: translateY(100px) rotate(-10deg);
  }
  60% {
    opacity: 0.5;
    transform: translateY(-10px) rotate(5deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0);
  }
}

@keyframes colorChange {
  0% {
    color: #ff6347; /* Tomato */
  }
  25% {
    color: #ffa500; /* Orange */
  }
  50% {
    color: #3cb371; /* Medium Sea Green */
  }
  75% {
    color: #1e90ff; /* Dodger Blue */
  }
  100% {
    color: #ff6347; /* Tomato */
  }
}

.home-content {
  text-align: center;
  padding: 20px;
  overflow: hidden;
}

.home-content h1,
.home-content h5 {
  display: inline-block;
  white-space: nowrap;
  opacity: 0;
  animation: colorChange 10s infinite, bounceInLetters 2s ease-out forwards;
}

.home-content h1 {
  font-size: 3rem;
  animation-delay: 0.3s;
}

.home-content h5 {
  font-size: 1.5rem;
  animation-delay: 1.5s;
}

@media (max-width: 1200px) {
  .home-content h1 {
    font-size: 2.5rem;
  }

  .home-content h5 {
    font-size: 1.3rem;
  }
}

@media (max-width: 992px) {
  .home-content h1 {
    font-size: 2rem;
  }

  .home-content h5 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .home-content h1 {
    font-size: 1.8rem;
  }

  .home-content h5 {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .home-content h1 {
    font-size: 1.5rem;
  }

  .home-content h5 {
    font-size: 0.9rem;
  }
}

.responsive-img {
  width: 100%;
  height: auto;
}

.carousel {
  width: 100%;
  margin: 0 auto;
}

.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.carousel-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

@media (max-width: 768px) {
  .carousel-caption h3 {
    font-size: 1.5rem;
  }

  .carousel-caption p {
    font-size: 1rem;
  }

  .carousel-item img {
    height: 250px;
  }
}

@media (max-width: 576px) {
  .carousel-caption h3 {
    font-size: 1.2rem;
  }

  .carousel-caption p {
    font-size: 0.9rem;
  }

  .carousel-item img {
    height: 200px;
  }
}

.categories {
  text-align: center;
  padding: 40px 20px;
}

.categories-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-item {
  margin: 10px;
  text-align: center;
}

.category-item img {
  width: 100%;
  max-width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.category-item h3 {
  margin-top: 10px;
  font-size: 1.2rem;
}
