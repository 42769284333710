
.dialog-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-image {
    max-width: 100%;
    max-height: 90vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.dialog-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    color: white;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    line-height: 1;
    z-index: 1000;
}

.dialog-close-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
