.subscribe-button {
  position: relative;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 16px;
  font-size: 12px;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.subscribe-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
}

.subscribe-button span {
  display: inline-block;
  transition: transform 0.3s ease;
}

.subscribe-button:hover span {
  transform: scale(1.1);
}

.subscribe-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.5s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.1);
}

.subscribe-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.subscribe-form-container {
  display: flex;
  justify-content: flex-end;
}

.custom-newsletter-form {
  margin-left: 33%;
}

.custom-newsletter-form input.form-control {
  padding: 6px;
  font-size: 12px;
  height: 32px;
}

@media (max-width: 768px) {
  .subscribe-button {
    padding: 6px 16px;
    font-size: 12px;
    height: 32px;
  }

  .custom-newsletter-form {
    margin-left: 0;
    width: 100%;
  }

  .subscribe-form-container {
    justify-content: center;
  }

  .subscribe-button {
    width: 100%;
    text-align: center;
  }

  .custom-newsletter-form input.form-control {
    padding: 6px;
    font-size: 12px;
    height: 32px;
  }
}

@media (max-width: 576px) {
  .custom-newsletter-form {
    text-align: center;
  }

  .subscribe-button {
    width: 100%;
  }

  .subscribe-form-container {
    justify-content: center;
    flex-direction: column;
  }

  .custom-newsletter-form form {
    width: 100%;
  }
}
