.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.carousel-item img {
  filter: brightness(70%);
}

.animated-caption {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.5s;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.animated-caption h3,
.animated-caption p {
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.animated-caption h3 {
  font-size: clamp(1rem, 2.5vw, 2.5rem);
  line-height: 1.2;
}

.animated-caption p {
  font-size: clamp(0.875rem, 2vw, 1.25rem);
  line-height: 1.2;
}

@media (max-width: 1600px) {
  .animated-caption h3 {
    font-size: 2.5rem;
  }

  .animated-caption p {
    font-size: 1.5rem;
  }
}

@media (max-width: 1440px) {
  .animated-caption h3 {
    font-size: 2.25rem;
  }

  .animated-caption p {
    font-size: 1.4rem;
  }
}

@media (max-width: 1200px) {
  .animated-caption h3 {
    font-size: 2rem;
  }

  .animated-caption p {
    font-size: 1.25rem;
  }
}

@media (max-width: 992px) {
  .animated-caption h3 {
    font-size: 1.75rem;
  }

  .animated-caption p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .animated-caption h3 {
    font-size: 1.5rem;
  }

  .animated-caption p {
    font-size: 0.875rem;
  }
}

@media (max-width: 576px) {
  .animated-caption h3 {
    font-size: 1.25rem;
  }

  .animated-caption p {
    font-size: 0.75rem;
  }
}

@media (max-width: 414px) {
  .animated-caption h3 {
    font-size: 1rem;
  }

  .animated-caption p {
    font-size: 0.625rem;
  }
}

@media (max-width: 360px) {
  .animated-caption h3 {
    font-size: 0.875rem;
  }

  .animated-caption p {
    font-size: 0.5rem;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
