.category-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-in-out;
}

.category-info {
  text-align: center;
  margin-bottom: 20px;
}

.category-info h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
}

.category-info p {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

.search-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.places-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

.place-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: slideIn 0.5s ease;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
}

.place-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.place-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.place-card:hover .place-image {
  transform: scale(1.05);
}

.place-info {
  padding: 15px;
  text-align: left;
}

.place-info h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.place-info p {
  font-size: 16px;
  color: #666;
  margin-top: 5px;
}

.place-address {
  font-style: italic;
  color: #555;
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  border: 1px solid #ccc;
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-item.active,
.page-item:hover {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.page-item:focus {
  outline: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

