.navbar {
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-link,
.logo-text,
.top-btn {
  transition: all 0.2s ease-in-out;
  color: inherit;
  text-decoration: none;
  margin-right: 20px;
}

.nav-link:hover {
  color: #fbc02d !important;
  background-color: rgba(173, 216, 230, 0.3);
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}

.active-link {
  font-weight: bold;
  color: #ff9800 !important;
  background-color: rgba(173, 216, 230, 0.4);
  border-radius: 5px;
  padding: 5px 10px;
}

.active-link:hover {
  background-color: rgba(173, 216, 230, 0.6);
  color: #fbc02d !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #283593;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1000;
}

.top-btn:hover {
  background-color: #1a237e;
  transform: scale(1.2);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.main-content {
  padding-top: 70px;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  width: auto;
  height: 50px;
  margin-right: 10px;
}

.logo-text {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: bold;
  display: inline-block;
}

.logo-part1 {
  color: #fbc02d;
}

.logo-part2 {
  color: #ff9800;
}

@media (max-width: 768px) {
  .nav-link {
    margin: 5px 0;
  }

  .logo-text {
    font-size: 28px;
  }
}

@media (max-width: 576px) {
  .logo-text {
    font-size: 24px;
  }
}

.user-name {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: bold;
  color: #333;
}

.logo-text {
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.logo-part1,
.logo-part2 {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  animation: slideIn 0.8s forwards ease-in-out;
  animation-delay: calc(var(--i) * 0.1s);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.logo-part1:nth-child(1) {
  --i: 1;
}
.logo-part1:nth-child(2) {
  --i: 2;
}
.logo-part1:nth-child(3) {
  --i: 3;
}
.logo-part1:nth-child(4) {
  --i: 4;
}
.logo-part2:nth-child(5) {
  --i: 5;
}
.logo-part2:nth-child(6) {
  --i: 6;
}
.logo-part2:nth-child(7) {
  --i: 7;
}
.logo-part2:nth-child(8) {
  --i: 8;
}
.logo-part2:nth-child(9) {
  --i: 9;
}

.logo-text:hover .logo-part1,
.logo-text:hover .logo-part2 {
  transform: translateY(-100%);
  opacity: 0;
  animation: hoverSlideOut 0.6s forwards ease-in-out;
}

@keyframes slideIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hoverSlideOut {
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes scrollAppear {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scrollDisappear {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.logo-container.scroll-up {
  animation: scrollAppear 0.8s forwards ease-in-out;
}

.logo-container.scroll-down {
  animation: scrollDisappear 0.8s forwards ease-in-out;
}

@media (max-width: 768px) {
  .logo-part1,
  .logo-part2 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .logo-part1,
  .logo-part2 {
    font-size: 18px;
  }
}

.search-results {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.search-results .MuiMenuItem-root {
  cursor: pointer;
}

.search-results .MuiMenuItem-root:hover {
  background-color: #f0f0f0;
}
