.categories-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.categories-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.search-sort-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sort-button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-button:hover {
  background-color: #0056b3;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 20px);
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
  background-color: #fff;
  font-size: 1rem;
  position: relative;
  z-index: 1;
}

.category-item.active {
  transform: scale(1.1);
  z-index: 3;
}

.category-item.hide {
  transform: scale(0.9) translate(0, 100px);
  opacity: 0;
}

.category-item.show {
  opacity: 1;
  transform: scale(1);
}

.category-item:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.category-item:hover ~ .category-item,
.category-item:hover + .category-item,
.category-item:hover + .category-item ~ .category-item {
  transform: scale(0.9) translate(30px, 30px);
  z-index: 0;
}

.category-item:nth-child(3n + 1):hover ~ .category-item:nth-child(3n + 2),
.category-item:nth-child(3n + 1):hover ~ .category-item:nth-child(3n + 3),
.category-item:nth-child(3n + 1):hover,
.category-item:nth-child(3n + 1):hover ~ .category-item:nth-child(n + 4) {
  transform: scale(0.9) translate(30px, 0);
}

.category-item:nth-child(3n + 2):hover ~ .category-item:nth-child(3n + 3),
.category-item:nth-child(3n + 2):hover,
.category-item:nth-child(3n + 2):hover ~ .category-item:nth-child(n + 4) {
  transform: scale(0.9) translate(30px, 0);
}

.category-item:nth-child(3n + 2):hover ~ .category-item:nth-child(3n + 1),
.category-item:nth-child(3n + 3):hover ~ .category-item:nth-child(3n + 1),
.category-item:nth-child(3n + 3):hover ~ .category-item:nth-child(3n + 2),
.category-item:nth-child(3n + 3):hover,
.category-item:nth-child(3n + 3):hover ~ .category-item:nth-child(n + 4) {
  transform: scale(0.9) translate(-30px, 0);
}

.category-item:hover ~ .category-item:nth-child(3n + 1),
.category-item:hover ~ .category-item:nth-child(3n + 2),
.category-item:hover ~ .category-item:nth-child(3n + 3) {
  transform: scale(0.9) translate(0, 30px);
}

.category-item:nth-child(3n + 1):hover {
  transform: scale(1.1) translate(0, -30px);
}

.category-item:nth-child(3n + 2):hover {
  transform: scale(1.1) translate(0, -30px);
}

.category-item:nth-child(3n + 3):hover {
  transform: scale(1.1) translate(0, -30px);
}

.category-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.category-item h3 {
  margin: 10px 0;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.category-item p {
  margin: 0 10px 10px;
  font-size: 1em;
  color: #666;
}

@media (max-width: 992px) {
  .category-item {
    width: calc(50% - 20px);
    font-size: 0.9rem;
  }

  .category-item h3 {
    font-size: 1.1em;
  }

  .category-item p {
    font-size: 0.9em;
  }
}

@media (max-width: 576px) {
  .category-item {
    width: calc(100% - 20px);
    font-size: 0.85rem;
  }

  .category-item h3 {
    font-size: 1em;
  }

  .category-item p {
    font-size: 0.85em;
  }
}
