.place-management-page {
  padding: 20px;
}

.place-management-title {
  margin-bottom: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  border: 1px solid #ccc;
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-item.active,
.page-item:hover {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.page-item:focus {
  outline: none;
}
