.container {
  margin-top: 40px;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.search-and-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.search-box {
  flex: 1;
  min-width: 200px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
}

.button {
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  text-transform: none;
  min-width: 130px;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.button:hover {
  transform: translateY(-2px);
}

.button-blue {
  background-color: #007bff;
}

.button-blue:hover {
  background-color: #0056b3;
}

.button-green {
  background-color: #28a745;
}

.button-green:hover {
  background-color: #218838;
}

.button-purple {
  background-color: #6f42c1;
}

.button-purple:hover {
  background-color: #5a32a3;
}

.button-orange {
  background-color: #ff9800;
}

.button-orange:hover {
  background-color: #e68900;
}

.button-indigo {
  background-color: #3f51b5;
}

.button-indigo:hover {
  background-color: #2e3b8e;
}

.button-red {
  background-color: #dc3545;
}

.button-red:hover {
  background-color: #c82333;
}

.icon-button {
  color: #dc3545;
  transition: color 0.3s, transform 0.3s;
}

.icon-button:hover {
  color: #b21f2d;
  transform: scale(1.1);
}

.table-container {
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.table {
  min-width: 800px;
}

.table-head {
  background-color: #f8f9fa;
}

.table-head-cell {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
  font-size: 0.9rem;
}

.table-body-row:hover {
  background-color: #f1f3f5;
  cursor: pointer;
}

.table-cell {
  text-align: center;
  font-size: 0.85rem;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .search-and-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .button-group {
    justify-content: center;
  }

  .table,
  .table-head-cell,
  .table-cell {
    font-size: 0.75rem;
  }

  .table-cell-actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
}
