.table-container {
  width: 100%;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  overflow: hidden;
  border: 1px solid #444;
  background-color: #1c1c1c;
}

.table-head {
  background-color: #3a3a3a;
  border-bottom: 3px solid #555;
}

.table-head-cell {
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 16px 20px;
  background-color: #222;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.table-head-cell:hover {
  /* background-color: #333; */
}

.sort-icon {
  margin-left: 8px;
  font-size: 16px;
  color: #888;
  transition: color 0.3s ease;
}

.table-head-cell.active .sort-icon {
  color: #00bcd4;
}

.table-body-row:hover {
  background-color: #333;
}

.table-cell {
  padding: 16px 20px;
  border-bottom: 1px solid #444;
  font-size: 14px;
  color: #e0e0e0;
}

.table-cell:first-of-type {
  border-left: 0px solid #555;
}

.table-cell:last-of-type {
  border-right: 0px solid #555;
}

.action-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.full-width-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  color: #ffffff;
  background: #444;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  transition: background-color 0.4s ease, box-shadow 0.3s ease;
  font-size: 16px;
}

.full-width-button:hover {
  background-color: #666;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.6);
}

.full-width-button .MuiButton-startIcon {
  margin-right: 12px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .table-head-cell,
  .table-cell {
    font-size: 12px;
    padding: 12px;
  }

  .full-width-button {
    font-size: 14px;
  }
}
