.notification-container {
  position: fixed;
  top: 150px;
  right: 20px;
  width: 350px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
  z-index: 1000;
  transform: translateY(0);
}

.notification-success {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.notification-error {
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  color: #721c24;
}

.notification-message {
  margin: 0;
  font-size: 16px;
}

.notification-exit {
  opacity: 0;
  transform: translateY(-20px);
}

.notification-close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  color: inherit;
  cursor: pointer;
}
