.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.styled-button {
  min-width: 120px;
}

.styled-button-primary {
  background-color: #1976d2;
  color: white;
}

.styled-button-secondary {
  border-color: #1976d2;
  color: #1976d2;
}

.styled-button-secondary:hover {
  background-color: rgba(25, 118, 210, 0.04);
}
.photos-preview img {
  margin: 10px;
  max-height: 150px;
}
.photos-preview {
  display: flex;
  flex-wrap: wrap;
}

.photo-container {
  margin: 10px;
  position: relative;
}

.photo-container img {
  max-height: 150px;
  border-radius: 4px;
}

.photo-container button {
  position: absolute;
  top: 5px;
  right: 5px;
}
